import { create } from 'zustand'

const useStore = create((set) => {
  return {
    router: null,
    dom: null
  }
})

export default useStore

import { useRef } from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import gsap from 'gsap'
import styles from './style.module.css'
import { useIsoLayoutEffect } from '../../hooks'
import loadingStates from '../../stores/loadingStates'
import AnubiLogo from '../../public/anubi.svg'

const PageTransition = ({
  location = '*'
}) => {
  const canvasReady = loadingStates((state) => state.canvasReady)

  let isPageReady = false

  switch (location) {
    case '/':
      isPageReady = canvasReady
      break
    default:
      isPageReady = true
      break
  }

  const bgRef = useRef()
  const bgSelector = gsap.utils.selector(bgRef)

  useIsoLayoutEffect(() => {
    if (isPageReady) {
      gsap.to(bgSelector('._stripe'), {
        width: 0,
        duration: 1,
        stagger: {
          amount: 0.3,
          from: 'random'
        }
      })
    }
  }, [isPageReady])

  return (
    <div className={styles.background} ref={bgRef}>
      <div className={classNames(styles.stripe, '_stripe')} />
      <div className={classNames(styles.stripe, '_stripe')} />
      <div className={classNames(styles.stripe, '_stripe')} />
      <div className={classNames(styles.stripe, '_stripe')}>
        <div className={styles.stripeText}>
          <Image priority src={AnubiLogo} width={64} alt='Anubi Logo' />
        </div>
      </div>
      <div className={classNames(styles.stripe, '_stripe')} />
      <div className={classNames(styles.stripe, '_stripe')} />
      <div className={classNames(styles.stripe, '_stripe')} />
    </div>
  )
}

export default PageTransition

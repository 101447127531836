import { useEffect, useState } from 'react'

export default function useAnalyticsTerms () {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  useEffect(() => {
    const fromLocalStorage = typeof window !== 'undefined' ? window.localStorage.getItem('_analyticsAccepted') : null
    setHasAcceptedTerms(fromLocalStorage)
  }, [])

  return {
    hasAcceptedTerms,
    setHasAcceptedTerms
  }
}

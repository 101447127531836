export const isMobileDevice = () => {
  if (typeof window !== 'undefined') {
    const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|Mobile|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)
    const isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1

    const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)

    return isMobileUserAgent || isIpad || isTouchDevice
  }
  return false
}

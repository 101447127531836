import { useEffect, useRef } from 'react'
import useStore from '../../stores/dom'

const Dom = ({
  children
}) => {
  const ref = useRef(null)
  useEffect(() => {
    useStore.setState({ dom: ref })
  }, [])

  return (
    <div
      ref={ref}
    >
      {children}
    </div>
  )
}

export default Dom

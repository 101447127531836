import { create } from 'zustand'

const terms = create((set) => {
  return (
    {
      forceBannerVisibility: false,
      setForceBannerVisibility: v => set(() => ({
        forceBannerVisibility: v
      }))
    }
  )
})

export default terms

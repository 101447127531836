
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { useIsoLayoutEffect, useWindowResize } from '../hooks';
import { gtmPageView } from '../lib';
import CookieBanner from '../components/CookieBanner';
import Announcement from '../components/Announcement';
import Header from '../components/Header';
import '../styles/globals.css';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ScrollSmoother } from '../utils/scrollsmoother';
import PageTransition from '../components/PageTransition';
import { Inter, Epilogue } from 'next/font/google';
import { Analytics } from '@vercel/analytics/react';
import { isMobileDevice } from '../utils';
import MacScrollBar from '../components/MacScrollBar';
import Script from 'next/script';
import useStore from '../stores/dom';
import Dom from '../components/FullCanvas/Dom';
const FullCanvas = dynamic(() => import('../components/FullCanvas'), {
    ssr: false
});
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
// Fonts
const inter = Inter({ subsets: ['latin'], display: 'swap' });
const epilogue = Epilogue({ subsets: ['latin'], display: 'swap' });
function AnubiWebsite({ Component, pageProps, router }) {
    const smoother = useRef(null);
    useEffect(() => {
        useStore.setState({ router });
    }, [router]);
    const refreshGsapPlugins = () => {
        ScrollTrigger.refresh();
        ScrollSmoother.refresh();
    };
    useEffect(() => {
        const handleRouteChange = (url) => {
            gtmPageView(url); // send event to gtm
        };
        // When the component is mounted, subscribe to router changes
        // and log those page views
        router.events.on('routeChangeComplete', handleRouteChange);
        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
    useIsoLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const _server = typeof window === 'undefined';
            const _mobile = window.innerWidth < 800;
            if (_server)
                return;
            if (!_mobile) {
                smoother.current = ScrollSmoother.create({
                    effects: true
                });
            }
        });
        return () => {
            // Clean up
            ctx.revert();
            smoother?.current && smoother.current.kill();
        };
    }, [router.pathname]);
    useWindowResize(() => {
        const _server = typeof window === 'undefined';
        const _mobile = window.innerWidth < 800;
        if (_server)
            return;
        if (_mobile) {
            smoother?.current && smoother.current.kill();
        }
        else {
            refreshGsapPlugins();
            smoother.current = ScrollSmoother.create({
                effects: true
            });
        }
    });
    const smoothScrollTo = (id) => {
        gsap.to(smoother.current, {
            // don't let it go beyond the maximum scrollable area
            scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.current.offset(id)),
            duration: 1.2,
            ease: 'Power2.easeInOut'
        });
    };
    const onScrollTo = async (id) => {
        const prevRoute = router.state.pathname;
        if (prevRoute !== '/') {
            await router.push('/');
            await smoothScrollTo(id);
        }
        else {
            smoothScrollTo(id);
        }
    };
    const loadFonts = (<style jsx global>{`
      html {
        /* Typography */
        --text: ${inter.style.fontFamily}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        --text-info: ${inter.style.fontFamily}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        --text-fancy: ${epilogue.style.fontFamily}, Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      }
      `}
    </style>);
    return (<>
      {loadFonts}
      <PageTransition location={router.pathname}/>
      <CookieBanner />
      <FullCanvas>{!isMobileDevice() && Component.canvas ? Component.canvas(pageProps) : null}</FullCanvas>
      <Dom>
        <div id='smooth-wrapper'>
          <div id='smooth-content'>
            {router.asPath === '/' && <Announcement />}
            <Header onScrollTo={onScrollTo}/>
            <Component key={router.route} {...pageProps}/>
          </div>
        </div>
      </Dom>
      <Analytics />
      <MacScrollBar />
      <Script src='https://www.googletagmanager.com/gtag/js?id=AW-11060337021'/>
      <Script id='google-analytics'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-11060337021');
        `}
      </Script>
    </>);
}
const __Next_Translate__Page__1921e28a654__ = AnubiWebsite;

    export default __appWithI18n(__Next_Translate__Page__1921e28a654__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  
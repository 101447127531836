import { useEffect } from 'react'

export default function useOnClickOutside (ref, mobile = true, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }

    !mobile && document.addEventListener('mousedown', listener)
    mobile && document.addEventListener('touchstart', listener)

    return () => {
      !mobile && document.removeEventListener('mousedown', listener)
      mobile && document.removeEventListener('touchstart', listener)
    }
  }, [ref, mobile, handler])
}

import { useEffect, useRef } from 'react'

export default function useMousePosition (debounceTime = 20) {
  const mousePosition = useRef({ x: 0, y: 0 })
  const debounceTimeout = useRef()

  useEffect(() => {
    return () => {
      // Clean up debounce timeout on unmount
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, [])

  const onMouseMove = (event) => {
    // Debounce setting the tooltip position so it doesn't update on every mouse move event
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }

    debounceTimeout.current = setTimeout(() => {
      mousePosition.current.x = event.clientX
      mousePosition.current.y = event.clientY
    }, debounceTime)
  }

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove)
    return () => window.removeEventListener('mousemove', onMouseMove)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    x: mousePosition.current.x,
    y: mousePosition.current.y
  }
}

import { useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Link from 'next/link'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import BurgerMenuIcon from '../BurgerMenuIcon'
import MobileMenu from '../MobileMenu'

import styles from './style.module.css'
import layoutStyles from '../../styles/Layout.module.css'
import AnubiLogo from '../../public/anubi.svg'

export default function Header ({
  onScrollTo = () => {}
}) {
  const router = useRouter()
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const { t } = useTranslation('common')
  const toggleMobileMenu = () => {
    setIsMenuOpened(prev => !prev)
  }

  const _onScrollTo = (e, id) => {
    if (e && id) {
      e.preventDefault()
      onScrollTo(id)
    }
  }

  return (
    <>
      <MobileMenu
        isOpened={isMenuOpened}
        onLinkClicked={(id) => {
          setIsMenuOpened(false)
          if (id) {
            router.push(`/${id}`)
          }
        }}
      />
      <div className={layoutStyles.siteContainer}>
        <div className={layoutStyles.main}>
          <div className={classNames(layoutStyles.container, styles.headerContainer)}>
            <header className={styles.header}>
              <div
                className={styles.logoContainer}
              >
                <Link
                  className={styles.logoBox}
                  href='/'
                  passHref
                >
                  <Image
                    className={styles.logo}
                    src={AnubiLogo}
                    alt='Anubi Logo'
                    width='64'
                    height='64'
                    style={{
                      maxWidth: '100%',
                      height: 'auto'
                    }}
                    priority
                  />
                </Link>
                <Link
                  className={styles.brand}
                  href='/'
                  passHref
                >
                  <div className={styles.brandTitle} translate='no'>Anubi</div>
                  <div className={styles.brandDescription} translate='no'>Creative Studio</div>
                </Link>
              </div>
              <div
                className={styles.headerButtonsContainer}
              >
                <Link
                  className={styles.link}
                  href='#featured'
                  passHref
                  onClick={(e) => {
                    _onScrollTo(e, '#featured')
                  }}
                >
                  {t('nav_featured')}
                </Link>
                <Link
                  className={styles.link}
                  href='#services'
                  passHref
                  onClick={(e) => {
                    _onScrollTo(e, '#services')
                  }}
                >
                  {t('nav_services')}
                </Link>
                <Link
                  className={styles.link}
                  href='/work/all'
                  passHref
                >
                  {t('nav_allwork')}
                </Link>
                <Link
                  href='/contact'
                  passHref
                >
                  <div className={classNames(styles.headerButton, styles.headerButtonFull)}>
                    {t('contact')}
                  </div>
                </Link>
              </div>
              <BurgerMenuIcon onClick={toggleMobileMenu} isOpened={isMenuOpened} />
            </header>
          </div>
        </div>
      </div>
    </>
  )
}

import { GlobalScrollbar } from 'mac-scrollbar'
import 'mac-scrollbar/dist/mac-scrollbar.css'

function MacScrollBar () {
  let isWindows = false

  if (typeof window !== 'undefined') {
    isWindows = /Win/i.test(window.navigator.userAgent)
  }

  if (!isWindows) return null

  return (
    <>
      <style jsx global>{`
        .ms-track-global {
          z-index: 999999999!important;
        }
      `}
      </style>
      <GlobalScrollbar />
    </>
  )
}

export default MacScrollBar

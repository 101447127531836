import { announcement as announcementData } from '../../data'
import styles from './style.module.css'

export default function Announcement () {
  const { isActive, externalLink, text } = announcementData

  if (!isActive) {
    return null
  }
  return (
    <div className={styles.announcement}>
      <span>
        <span role='img'>✨</span>{' '}
        {externalLink ? <a rel='noopener noreferrer' href={externalLink} target='_blank'>{text}</a> : { text }}
        {' '}<span role='img'>✨</span>
      </span>
    </div>
  )
}

import { create } from 'zustand'

const loadingStates = create((set) => {
  return (
    {
      everLoaded: false,
      setEverLoaded: v => set(() => ({
        everLoaded: v
      })),
      canvasReady: false,
      setCanvasReady: v => set(() => ({
        canvasReady: v
      }))
    }
  )
})

export default loadingStates

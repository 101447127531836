import { useEffect } from 'react'

const useWindowResize = (fn = () => {}, timeout = 250) => {
  useEffect(() => {
    let timeoutId = null

    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        fn()
      }, timeout)
    }

    window.addEventListener('resize', resizeListener)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', resizeListener)
    }
  }, [fn, timeout])

  return null
}

export default useWindowResize

import { useEffect, useRef } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import gsap from 'gsap'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { useIsoLayoutEffect, useWindowResize } from '../../hooks'

import styles from './style.module.css'
import AnubiLogoWhiteNoBg from '../../public/anubi-white-nobg.svg'

export default function MobileMenu ({
  isOpened = false,
  onLinkClicked = () => {}
}) {
  const { t } = useTranslation('common')
  const mobileMenuRef = useRef()

  useIsoLayoutEffect(() => {
    window.onscroll = (e) => {
      if (isOpened) {
        e.preventDefault()
        window.scroll(0, 0)
      }
    }
    gsap.to(mobileMenuRef.current, {
      x: isOpened ? '0vw' : '-100vw',
      height: window.innerHeight,
      ease: isOpened ? 'power4' : 'power2',
      duration: isOpened ? 1 : 0.3
    })
    gsap.to('._mobileLi', {
      opacity: isOpened ? 1 : 0,
      x: isOpened ? 0 : -100,
      delay: isOpened ? 0.18 : 0,
      duration: isOpened ? 0.6 : 0.4,
      stagger: isOpened ? 0.1 : 0,
      ease: 'power4'
    })
  }, [isOpened])

  useEffect(() => {
    if (isOpened) {
      document.documentElement.classList.add('lock-ios-scroll')
    } else {
      document.documentElement.classList.remove('lock-ios-scroll')
    }
  }, [isOpened])

  useWindowResize(() => {
    if (isOpened && window.innerWidth > 779) {
      // close mobile menu on desktop window resize
      onLinkClicked()
    }
  })

  return (
    <div
      ref={mobileMenuRef}
      className={styles.mobileMenuWrapper}
    >
      <div className={styles.mobileMenu}>
        <ul className={styles.mobileMenuUl}>
          <li className={classNames(styles.mobileLi, '_mobileLi')}>
            <Link
              className={styles.logoBox}
              href='/'
              onClick={() => {
                onLinkClicked()
              }}
            >
              <Image
                className={styles.logo}
                src={AnubiLogoWhiteNoBg}
                alt='Anubi Logo'
                width='52'
                height='52'
                style={{
                  maxWidth: '100%',
                  height: 'auto'
                }}
              />
            </Link>
          </li>
          <li className={classNames(styles.mobileLi, '_mobileLi')}>
            <Link
              href='#featured'
              passHref
              onClick={(e) => {
                e.preventDefault()
                onLinkClicked('#featured')
              }}
            >
              {t('nav_featured')}
            </Link>
          </li>
          <li className={classNames(styles.mobileLi, '_mobileLi')}>
            <Link
              href='#services'
              passHref
              onClick={(e) => {
                e.preventDefault()
                onLinkClicked('#services')
              }}
            >
              {t('nav_services')}
            </Link>
          </li>
          <li className={classNames(styles.mobileLi, '_mobileLi')}>
            <Link
              href='/work/all'
              passHref
              onClick={() => {
                onLinkClicked()
              }}
            >
              {t('nav_allwork')}
            </Link>
          </li>
          <li className={classNames(styles.mobileLi, '_mobileLi')}>
            <Link
              href='/contact'
              onClick={() => {
                onLinkClicked()
              }}
            >
              {t('contact')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

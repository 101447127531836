import useTranslation from 'next-translate/useTranslation'

const useWorkTranslation = (meta = {}, work = null) => {
  const { lang } = useTranslation()

  const t = (keys = '') => {
    if (!keys || keys === '') return ''
    let mapped = []
    if (keys.includes('.')) {
      const _keys = keys.split('.')
      mapped = _keys
    } else {
      mapped = [keys]
    }

    let value = null
    let start = {}

    if (work) {
      start = meta.work.copy[lang]
    } else {
      start = meta.copy[lang]
    }

    for (const key of mapped) {
      if (value) {
        value = value[key]
      } else {
        value = start[key]
      }
    }

    return value
  }

  return {
    lang,
    t
  }
}

export default useWorkTranslation

import Router from 'next/router'
import { useEffect } from 'react'

const useRouteChange = (
  start = () => {},
  complete = () => {},
  error = () => {}
) => {
  useEffect(() => {
    let timeoutIdComplete = null

    const handleRouteChangeStart = () => {
      start()
    }

    const handleRouteChangeError = () => {
      error()
    }

    const handleRouteChangeComplete = () => {
      clearTimeout(timeoutIdComplete)
      timeoutIdComplete = setTimeout(() => {
        complete()
      }, 100)
    }

    Router.events.on('routeChangeStart', handleRouteChangeStart)
    Router.events.on('routeChangeComplete', handleRouteChangeComplete)
    Router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart)
      Router.events.off('routeChangeComplete', handleRouteChangeComplete)
      Router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [start, complete, error])
}

export default useRouteChange

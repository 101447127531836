import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import Script from 'next/script'
import { useAnalyticsTerms } from '../../hooks'
import terms from '../../stores/terms'
import styles from './style.module.css'

export default function CookieBanner () {
  const { t } = useTranslation('common')

  const { hasAcceptedTerms, setHasAcceptedTerms } = useAnalyticsTerms()

  const forceBannerVisibility = terms((state) => state.forceBannerVisibility)
  const setForceBannerVisibility = terms((state) => state.setForceBannerVisibility)

  function deleteCookies () {
    const allCookies = document.cookie.split(';')
    for (let i = 0; i < allCookies.length; i++) {
      document.cookie = allCookies[i] + '=;expires=' +
        new Date(0).toUTCString()
    }
  }

  return (
    <div
      className={classNames(styles.cookieBannerContainer, {
        [styles.cookieRead]: hasAcceptedTerms !== null && !forceBannerVisibility
      })}
    >
      <div className={styles.cookieBannerInner}>
        <span>
          {t('privacyBanner')} {' '}
          <a
            href='https://www.iubenda.com/privacy-policy/23452349'
            rel='noopener noreferrer'
            target='_blank'
          >{t('privacyPolicy')}
          </a>
          ,{' '}
          <a
            href='https://www.iubenda.com/privacy-policy/23452349/cookie-policy'
            rel='noopener noreferrer'
            target='_blank'
          >{t('cookiesPolicy')}
          </a>.
        </span>
        <div className={styles.actionRow}>
          <div
            className={styles.actionButton}
            onClick={() => {
              setForceBannerVisibility(false)
              setHasAcceptedTerms(false)
              deleteCookies()
            }}
          >
            {t('bannerRefuse')}
          </div>
          <div
            className={styles.actionButton}
            onClick={() => {
              setForceBannerVisibility(false)
              window.localStorage.setItem('_analyticsAccepted', true)
              setHasAcceptedTerms(true)
            }}
          >
            {t('bannerAccept')}
          </div>
        </div>
        {
          hasAcceptedTerms === true
            ? (
              <>
                {/* Google Tag Manager */}
                <noscript
                  dangerouslySetInnerHTML={{
                    __html: `<iframe
              src={'https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER}'}
              height='0' width='0' style='display:none;visibility:hidden'
            />`
                  }}
                />
                <Script
                  id='tag-manager'
                  strategy='afterInteractive'
                  dangerouslySetInnerHTML={{
                    __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER}');
            `
                  }}
                />
              </>
              )
            : null
        }
      </div>
    </div>
  )
}

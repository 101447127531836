import classNames from 'classnames'
import gsap from 'gsap'
import { useIsoLayoutEffect } from '../../hooks'
import styles from './style.module.css'

export default function BurgerMenuIcon ({
  isOpened = false,
  onClick
}) {
  useIsoLayoutEffect(() => {
    gsap.to('._burgerLineTop', {
      rotate: isOpened ? '45deg' : '0deg',
      x: isOpened ? 1 : 0,
      y: isOpened ? 9 : 0,
      duration: 0.2
    })
    gsap.to('._burgerLineMiddle', {
      opacity: isOpened ? 0 : 1,
      duration: 0.23
    })
    gsap.to('._burgerLineBottom', {
      width: isOpened ? 28 : 20,
      rotate: isOpened ? '-45deg' : '0deg',
      x: isOpened ? -1 : 0,
      y: isOpened ? -9 : 0,
      duration: 0.25
    })
    gsap.to('._burgerLine', {
      background: isOpened ? '#fff' : '#000',
      duration: isOpened ? 1.4 : 1,
      ease: 'power4'
    })
  }, [isOpened])

  return (
    <div className={styles.burgerWrapper} style={{ position: isOpened ? 'fixed' : 'relative', right: isOpened ? 12 : 0 }}>
      <button className={styles.burger} onClick={onClick}>
        <span className={classNames(styles.lineTop, '_burgerLine', '_burgerLineTop')} />
        <span className={classNames(styles.lineMiddle, '_burgerLine', '_burgerLineMiddle')} />
        <span className={classNames(styles.lineBottom, '_burgerLine', '_burgerLineBottom')} />
      </button>
    </div>
  )
}

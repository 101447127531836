export const gtmPageView = (url = '') => {
  if (typeof window !== 'undefined') {
    const hasAcceptedTerms = window.localStorage.getItem('_analyticsAccepted')
    if (hasAcceptedTerms) {
      window && window.dataLayer && window.dataLayer.push({
        event: 'page_view',
        page: url
      })
    }
  }
}

import { useMediaQuery } from 'react-responsive'

function useQuery () {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1400px)' })

  return {
    isSmallScreen
  }
}

export default useQuery
